import React from "react"
import MediaQuery from 'react-responsive';
import Typist from 'react-typist';
import Links from "../components/Links"
import Me from "../images/me.png"


const Info = () => (
  <div>
    <Typist cursor={{show: false}}>
      <h1>Hello!</h1>
    </Typist>
    <br/>
    <br/>
    <h3 style={{}}>
      I'm Eli Verbrugge <img src={Me} alt="Avatar" class="avatar"/>, a software engineer interested in building cool tech whether it be
      unique data insights or robots with awesome ways to control or enhance them.
    </h3>
    <br />
    <br />
    <Links/>
  </div> 
);


const Intro = () => (
  <div>
      {/* Render a grey background behind content for mobile devices */}
      <MediaQuery maxDeviceWidth={1224}>
      <div style={{backgroundColor: "rgba(128, 128, 128, .6)", textAlign: "center", marginTop: "10%", marginLeft: "10%", marginRight: "10%"}}>
        <Info/>
      </div>
      </MediaQuery>

      {/* Center text with some margins on desktop */}
      <MediaQuery minDeviceWidth={1224}>
      <div style={{textAlign: "center", marginTop: "10%", marginLeft: "10%", marginRight: "10%"}}>
        <Info/>
      </div>
      </MediaQuery>
  </div>
);

export default Intro;